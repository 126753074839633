import React from 'react';
import './App.css';
import OrganizationChart from './OrgChart';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Organization Chart</h1>
        <p>Click for extra details.</p>
        Search for employees by name, salary, department, or location.
      </header>
      <OrganizationChart />
    </div>
  );
}

export default App;
