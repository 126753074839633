import React, { useEffect, useState } from 'react';
import { getOrganizationChart, searchEmployees } from './apiService';
import './OrgChart.css';

const OrganizationChart = () => {
    const [orgData, setOrgData] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState({
        name: '',
        minSalary: '',
        maxSalary: '',
        department: '',
        office: '',
        skills: ''
    });
    const [visibleDepartments, setVisibleDepartments] = useState({});

    useEffect(() => {
        getOrganizationChart()
            .then(data => {
                setOrgData(data);
                setIsLoading(false);
            })
            .catch(err => {
                console.error('Error loading organization chart:', err);
                setError(err);
                setIsLoading(false);
            });
    }, []);

    const handleNodeClick = (node) => {
        if (node.employees) {
            setVisibleDepartments(prevState => ({
                ...prevState,
                [node.name]: !prevState[node.name]
            }));
        } else {
            setSelectedNode(node);
        }
    };

    const handleSearchChange = (e) => {
        setSearchCriteria({ ...searchCriteria, [e.target.name]: e.target.value });
    };

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        if (Object.values(searchCriteria).every(field => !field)) {
            setSearchResults([]);
            return;
        }
        try {
            const results = await searchEmployees(searchCriteria);
            setSearchResults(results.employees);
        } catch (error) {
            console.error('Error searching employees:', error);
        }
    };

    const renderEmployee = (employee) => (
        <div key={employee.name} className="employee-node" onClick={() => handleNodeClick(employee)}>
            {employee.name}
        </div>
    );

    const renderDepartment = (department) => (
        <div key={department.name} className="department-row">
            <div className="department-node" onClick={() => handleNodeClick(department)}>
                {department.name}
            </div>
            {visibleDepartments[department.name] && (
                <div className="employee-container">
                    {department.employees.map(renderEmployee)}
                </div>
            )}
        </div>
    );

    return (
        <div className="org-chart-container">
            <form onSubmit={handleSearchSubmit} className="search-form">
                <input type="text" name="name" placeholder="Name" value={searchCriteria.name} onChange={handleSearchChange} />
                <input type="number" name="minSalary" placeholder="Min Salary" value={searchCriteria.minSalary} onChange={handleSearchChange} />
                <input type="number" name="maxSalary" placeholder="Max Salary" value={searchCriteria.maxSalary} onChange={handleSearchChange} />
                <input type="text" name="department" placeholder="Department" value={searchCriteria.department} onChange={handleSearchChange} />
                <input type="text" name="office" placeholder="Office" value={searchCriteria.office} onChange={handleSearchChange} />
                <input type="text" name="skills" placeholder="Skills" value={searchCriteria.skills} onChange={handleSearchChange} />
                <button type="submit">Search</button>
            </form>
            <div className="search-results">
                {searchResults.map(employee => (
                    <div key={employee.id} className="employee-node" onClick={() => handleNodeClick(employee)}>
                        {employee.name} - {employee.department}
                    </div>
                ))}
            </div>
            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error loading data: {error.message}</p>
            ) : orgData && orgData.organization ? (
                <div>
                    <div className="organization-node" onClick={() => handleNodeClick({ name: orgData.organization.name })}>
                        {orgData.organization.name}
                    </div>
                    {orgData.organization.departments.map(renderDepartment)}
                </div>
            ) : (
                <p>No organization data available</p>
            )}
            {selectedNode && (
                <div className="node-details">
                    <h3>Node Details</h3>
                    <p>Name: {selectedNode.name}</p>
                    {selectedNode.department && <p>Department: {selectedNode.department}</p>}
                    {selectedNode.salary && <p>Salary: {selectedNode.salary}</p>}
                    {selectedNode.office && <p>Office: {selectedNode.office}</p>}
                </div>
            )}
        </div>
    );
};

export default OrganizationChart;
