const API_BASE_URL = 'https://cloudflare-studen2.diegoquinones.workers.dev';

export const getOrganizationChart = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/organization-chart`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching organization chart:', error);
  }
};

export const searchEmployees = async (searchCriteria) => {
    const response = await fetch(`https://cloudflare-studen2.diegoquinones.workers.dev/employee`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchCriteria),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    return await response.json();
};

